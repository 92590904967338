<template>
  <section class="mt-4">
    <header>
      <h2>{{ 5 | stepTitle }}</h2>
      <p>Le domande precedute da un asterisco sono obbligatorie.</p>
    </header>

    <Stepper class="mb-4" :step="5">
      <template #next-step-content>
        <button type="button" class="btn btn-success" @click.prevent="sendSurvey">
          Responso
        </button>
      </template>
    </Stepper>

    <Step5Form />

    <Stepper class="my-4" :step="5">
      <template #next-step-content>
        <button type="button" class="btn btn-success" @click.prevent="sendSurvey">
          Responso
        </button>
      </template>
    </Stepper>
  </section>
</template>

<script>

import Stepper from '@/views/components/Stepper.vue';
import stepTitleFilter from '@/filters/stepTitleFilter.js';
import surveyMixin from '@/mixins/surveyMixin.js';
import Step5Form from '@/views/components/Steps/Step5Form.vue';

export default {
  mixins: [surveyMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  components: {
    Stepper,
    Step5Form,
  },
};

</script>

<style lang="scss">
</style>
